define('ember-accordion/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.childOf('.AccordionPanel'), this.use('crossFade', { duration: 2000 }), this.reverse('toUp', { duration: 2000 }));
  };
});