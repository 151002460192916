define('ember-accordion/components/accordion-list', ['exports', 'ember-accordion/templates/components/accordion-list', 'ember-accordion/utils/item'], function (exports, _accordionList, _item) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AccordionListComponent = Ember.Component.extend({
    layout: _accordionList.default,
    classNames: ["AccordionList"],

    // Input params
    allowManyActiveItems: false,

    // Internal state
    _activeItems: null,
    _registeredItems: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_activeItems', Ember.A([]));
      this.set('_registeredItems', Ember.A([]));
    },


    // If each registered item has at least on panel open, then return true
    allExpanded: Ember.computed('_activeItems.[]', '_registeredItems.[]', function () {
      var _getProperties = this.getProperties('_activeItems', '_registeredItems'),
          _activeItems = _getProperties._activeItems,
          _registeredItems = _getProperties._registeredItems;

      var anyMissing = _registeredItems.reduce(function (anyMissing, item) {
        return anyMissing || Ember.isNone(_activeItems.findBy('id', item.itemId));
      }, false);
      return !anyMissing;
    }),

    actions: {
      toggleItem: function toggleItem(itemId, panelName) {
        var activeItems = this.get('_activeItems');
        var targetItem = activeItems.findBy('id', itemId);

        // if target item is already active
        if (targetItem) {
          // and the panel is already active
          if (Ember.isEqual(targetItem.get('panel'), panelName)) {
            activeItems.removeObject(targetItem);
          } else {
            targetItem.set('panel', panelName);
          }
        } else {
          // if simultanious active items are not allow, clear array
          if (!this.get('allowManyActiveItems')) {
            activeItems.clear();
          }

          var newItem = _item.default.create({ id: itemId, panel: panelName });
          activeItems.addObject(newItem);
        }
      },
      closeItem: function closeItem(itemId) {
        var activeItems = this.get('_activeItems');
        var activeItem = activeItems.findBy('id', itemId);
        activeItems.removeObject(activeItem);
      },
      expandAll: function expandAll(panelName) {
        panelName = panelName || 'panel-one';

        var _getProperties2 = this.getProperties('_activeItems', '_registeredItems'),
            _activeItems = _getProperties2._activeItems,
            _registeredItems = _getProperties2._registeredItems;

        _registeredItems.forEach(function (registeredItem) {
          if (registeredItem.panelName === panelName) {
            _activeItems.addObject(_item.default.create({ id: registeredItem.itemId, panel: panelName }));
          }
        });
      },
      collapseAll: function collapseAll() {
        this.get('_activeItems').clear();
      },
      register: function register(itemId, panelName) {
        this.get('_registeredItems').addObject({ itemId: itemId, panelName: panelName });
      },
      unregister: function unregister(itemId, panelName) {
        this.get('_registeredItems').removeObject({ itemId: itemId, panelName: panelName });
      }
    }
  });

  exports.default = AccordionListComponent;
});