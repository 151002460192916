define('ember-accordion/components/accordion-item', ['exports', 'ember-accordion/templates/components/accordion-item'], function (exports, _accordionItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AccordionItemComponent = Ember.Component.extend({
    layout: _accordionItem.default,
    classNames: ["AccordionItem"],

    itemId: Ember.computed(function () {
      return this.elementId;
    }),

    // item is active if there is an item in activeItems matching 'itemId'
    isActive: Ember.computed.notEmpty('ownActiveItem'),
    ownActiveItem: Ember.computed('activeItems.@each.id', 'itemId', function () {
      return this.get('activeItems').findBy('id', this.get('itemId'));
    }),

    activePanel: Ember.computed('isActive', 'ownActiveItem.panel', function () {
      if (!this.get('isActive')) {
        return null;
      }

      return this.get('ownActiveItem.panel');
    }),

    actions: {
      togglePanel: function togglePanel(panelName) {
        this.get('toggle')(this.get('itemId'), panelName);
      }
    }
  });

  AccordionItemComponent.reopenClass({
    positionalParams: ['activeItems']
  });

  exports.default = AccordionItemComponent;
});