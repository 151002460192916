define('ember-accordion/components/accordion-toggle', ['exports', 'ember-accordion/templates/components/accordion-toggle'], function (exports, _accordionToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AccordionToggleComponent = Ember.Component.extend({
    layout: _accordionToggle.default,
    classNames: ["AccordionToggle"],
    classNameBindings: ['isActive', 'disabled'],

    // Input params
    disabled: null,
    panelName: 'panel-one',

    isActive: Ember.computed('activePanelName', 'panelName', function () {
      return Ember.isEqual(this.get('activePanelName'), this.get('panelName'));
    }),

    click: function click() {
      if (!this.get('disabled')) {
        this.get('toggle')(this.get('panelName'));
      }
      return false;
    }
  });

  AccordionToggleComponent.reopenClass({
    positionalParams: ['activePanelName', 'toggle']
  });

  exports.default = AccordionToggleComponent;
});