define('ember-accordion/components/accordion-panel', ['exports', 'ember-accordion/templates/components/accordion-panel'], function (exports, _accordionPanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var AccordionPanelComponent = Ember.Component.extend({
    layout: _accordionPanel.default,
    classNames: ["AccordionPanel"],
    classNameBindings: ['isActive'],

    // Inputs
    panelName: 'panel-one',

    isActive: Ember.computed('activePanelName', 'panelName', function () {
      return Ember.isEqual(this.get('activePanelName'), this.get('panelName'));
    }),
    openOnInit: false,
    _activateDefaultPanel: function _activateDefaultPanel() {
      if (this.isDestroying) {
        return;
      }

      if (this.get('openOnInit')) {
        this.get('toggle')(this.get('panelName'));
      }
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.next(function () {
        _this.get('register')(_this.get('panelName'));
        _this._activateDefaultPanel();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.get('unregister')(this.get('panelName'));
    }
  });

  AccordionPanelComponent.reopenClass({
    positionalParams: ['activePanelName', 'toggle']
  });

  exports.default = AccordionPanelComponent;
});